require("./bootstrap");

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/inertia-vue3";
import { InertiaProgress } from "@inertiajs/progress";
import ElementPlus from "element-plus";
import VueApexCharts from "vue3-apexcharts";
import route from "ziggy";
import { Ziggy } from "./ziggy";
import { i18nVue } from "laravel-vue-i18n";
import "element-plus/dist/index.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

const appName =
    window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";

// const currentLang = window.location.pathname.split('/')

// window.localStorage.setItem('currentLang', 'en')
// console.log({ currentLang, location: window.location, pathname: window.location.pathname })
// console.log(window.localStorage.getItem('currentLang'));

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: async (name) => {
        return (await import(`./Pages/${name}.vue`)).default;
    },
    setup({ el, app, props, plugin }) {
        return createApp({ render: () => h(app, props) })
            .use(plugin)
            .use(ElementPlus)
            .use(VueApexCharts)
            .use(VueTelInput)
            .use(i18nVue, {
                resolve: (lang) => import(`../lang/${lang}.json`),
            })
            .mixin({
                methods: {
                    route: (name, params, absolute) =>
                        route(name, params, absolute, Ziggy),
                },
            })
            .mount(el);
    },
});

InertiaProgress.init({ color: "#4B5563" });
